<template>
    <div class="row">
        <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <div class="card course-card mw-100 mh-auto p-0 shadow-xss border-0 rounded-lg overflow-hidden px-3">
                        <div class="card-body">
                            <div v-if="!isLoad" class="col-12 py-3">
                                <form @submit.prevent="patchEvent" class="row">
                                    <div class="col-12">
                                        <div class="row p-auto">
                                            <div class="col-xl-12">
                                                <div class="avatar-upload" v-show="!edit">
                                                    <div class="text-center p-2">
                                                        <label for="avatar">
                                                            <img width="100%"
                                                                :src="files.length ? files[0].url : '/images/image-upload.png'" />
                                                        </label>
                                                    </div>
                                                    <div class="text-center p-2">
                                                        <file-upload extensions="jpg,jpeg,png"
                                                            accept="image/png,image/jpeg,image/jpg" name="avatar"
                                                            class="btn btn-current" :drop="!edit" v-model="files"
                                                            @input-filter="inputFilter" @input-file="inputFile" ref="upload"
                                                            required>
                                                            Upload Banner
                                                        </file-upload>
                                                    </div>
                                                </div>

                                                <div class="avatar-edit" v-show="files.length && edit">
                                                    <div class="avatar-edit-image" v-if="files.length">
                                                        <cropper class="cropper" ref="cropper" :src="files[0].url"
                                                            :stencil-props="{
                                                                handlers: {},
                                                                movable: false,
                                                                scalable: false,
                                                                aspectRatio: 1,
                                                            }" :resize-image="{
    adjustStencil: false
}" image-restriction="stencil"></cropper>
                                                    </div>
                                                    <div class="text-center p-4">
                                                        <button type="button" class="btn btn-default"
                                                            @click.prevent="$refs.upload.clear">Cancel</button>
                                                        <button type="submit" class="btn btn-current"
                                                            @click.prevent="editSave">Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-xl-6 form-group mb-3">
                                                <label for="">Title</label>
                                                <input type="text" v-model="form.title" required
                                                    class="form-control text-grey-900 font-xsss fw-600"
                                                    placeholder="Enter your Title">
                                            </div>
                                            <div class="col-xl-6 form-group mb-3">
                                                <label for="">Category</label>
                                                <select v-model="form.category_event_id" required
                                                    class="form-control text-grey-900 font-xsss fw-600">
                                                    <option value="" disabled>Select a category</option>
                                                    <option v-for="category in categories" :key="category.id"
                                                        :value="category.id">{{ category.name }}</option>
                                                </select>
                                            </div>
                                            <div class="col-xl-6 form-group mb-3">
                                                <label for="">Schedule</label>
                                                <input type="datetime-local" v-model="form.schedule"
                                                    class="form-control text-grey-900 font-xsss fw-600" value-type="format"
                                                    format="YYYY-MM-DD">
                                            </div>
                                            <div class="col-xl-6 form-group mb-3">
                                                <label for="">Location</label>
                                                <input type="text" v-model="form.location" required
                                                    class="form-control text-grey-900 font-xsss fw-600"
                                                    placeholder="Enter your Title">
                                            </div>
                                            <div class="col-12 form-group mb-3">
                                                <label for="">Content</label>
                                                <CustomCkEditor :ck-placeholder="'Enter your Content'"
                                                    :ck-value="form.content" @ck-on-editor-input="setContent($event)">
                                                </CustomCkEditor>
                                            </div>
                                            <div class="col-12 form-group text-right my-4">
                                                <router-link :to="{ name: 'EventAdmin' }"
                                                    class="btn btn-outline-warning mr-4" value="Submit">Cancel</router-link>
                                                <input type="Submit" class="btn btn-current" value="Submit">
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="col-12 text-center py-3" v-else>
                                <div class="my-3">
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import CustomCkEditor from '../../../components/helpers/CustomCkEditor.vue'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import FileUpload from 'vue-upload-component'
export default {
    name: "EventEdit",
    components: {
        CustomCkEditor,
        FileUpload,
        Cropper
    },
    data() {
        return {
            media: process.env.VUE_APP_URL_CLOUD,
            idArticle: this.$route.params.idArticle,
            isLoad: true,
            edit: false,
            form: {
                thumbnail: '',
                title: '',
                content: ''
            },
            categories: [],
            files: [
                {
                    url: ''
                }
            ],
            img: 'https://images.pexels.com/photos/226746/pexels-photo-226746.jpeg',
        }
    },
    created() {
        this.getEvent();
        this.getCategories();
    },
    methods: {
        async getEvent() {
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/events/event/detail?id=${this.idArticle}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.form = res.data.data
                this.files[0].url = this. this.form.thumbnail
                this.isLoad = false
            })
        },
        async getCategories() {
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/events/category/`, {
                headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
            }).then(res => {
                    this.categories = res.data.data;
                })
                .catch(err => {
                    console.error(err);
                });
        },
        async patchEvent() {
            let data = new FormData();
            data.append('id', this.idArticle);
            data.append('thumbnail', this.form.thumbnail);
            data.append('title', this.form.title);
            data.append('content', this.form.content);
            data.append('category_event_id', this.form.category_event_id);
            data.append('location', this.form.location);
            data.append('schedule', this.form.schedule);
            data.append('_method', 'patch');
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/events/event`, data, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.$swal({
                    toast: true,
                    title: 'Article',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.$router.push({ name: 'EventAdmin' })
            }).catch(err => {
                if (err.response.status == 422) {
                    this.$swal({
                        toast: true,
                        title: 'Arrticle',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        async editSave() {
            this.edit = false
            let oldFile = this.files[0]
            const result = this.$refs.cropper.getResult();
            let binStr = atob(result.canvas.toDataURL(oldFile.type).split(',')[1])
            let arr = new Uint8Array(binStr.length)
            for (let i = 0; i < binStr.length; i++) {
                arr[i] = binStr.charCodeAt(i)
            }
            let file = new File([arr], oldFile.name, { type: oldFile.type })
            this.$refs.upload.update(oldFile.id, {
                file,
                type: file.type,
                size: file.size,
                active: true,
            })

            let data = new FormData();
            data.append('dir', 'events/thumbnail');
            data.append('attachment', this.files[0].file);
            data.append('_token', 'elingwaysecretkey');
            await axios.post(`${process.env.VUE_APP_URL_MEDIA}/api/storage/store-cloud`, data, { withCredentials: false }, {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.files[0].response = res.data
                this.form.thumbnail = res.data.path
            }).catch(err => {
                console.log(err.response)
            })
        },
        alert(message) {
            alert(message)
        },
        setContent(value) {
            this.form.content = value
        },
        inputFile(newFile, oldFile) {
            if (newFile && !oldFile) {
                this.$nextTick(function () {
                    this.edit = true
                })
            }
            if (!newFile && oldFile) {
                this.edit = false
            }
        },
        inputFilter(newFile, oldFile, prevent) {
            if (newFile && !oldFile) {
                if (!/\.(jpg|png|jpeg)$/i.test(newFile.name)) {
                    this.alert('Your choice is not a picture')
                    return prevent()
                }
            }
            if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
                newFile.url = ''
                let URL = window.URL || window.webkitURL
                if (URL && URL.createObjectURL) {
                    newFile.url = URL.createObjectURL(newFile.file)
                }
            }
        },
    }
}
</script>
<style scoped>
.cropper {
    height: 600px;
    background: #DDD;
}
</style>
